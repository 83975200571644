<script lang="ts">
    import * as Dialog from '$lib/components/ui/dialog';
    import { Button } from '$lib/components/ui/button';
    import toast from 'svelte-french-toast';

    export let show: boolean = false;
    export let userId: number;
    export let app: { baseUrl: string } = { baseUrl: '' };

    type Follower = {
        avatar: string;
        name: string;
        username: string;
    };

    let followers: Follower[] = [];

    async function fetchFollowers(): Promise<void> {
        try {
            const response = await fetch(`${app.baseUrl}/followers/${userId}`);
            const text = await response.text();
            const jsonStr = text.split('<link')[0];
            const result = JSON.parse(jsonStr);
            followers = result.data || [];
        } catch (error) {
            toast.error('Failed to load followers');
            console.error('Error fetching followers:', error);
        }
    }

    $: if (show) {
        fetchFollowers();
    }
</script>

<Dialog.Root bind:open="{show}">
    <Dialog.Content>
        <Dialog.Header>
            <Dialog.Title>Followers</Dialog.Title>
        </Dialog.Header>

        <div class="max-h-[60vh] overflow-y-auto">
            {#if followers.length > 0}
                <ul class="space-y-3">
                    {#each followers as follower}
                        <li class="flex items-center space-x-3">
                            <img
                                src="{follower.avatar}"
                                alt="{follower.name}"
                                class="h-10 w-10 rounded-full object-cover"
                            />
                            <div>
                                <p class="font-medium">{follower.name}</p>
                                <p class="text-sm text-gray-500">@{follower.username}</p>
                            </div>
                        </li>
                    {/each}
                </ul>
            {:else}
                <p class="text-center text-gray-500">No followers yet</p>
            {/if}
        </div>

        <div class="mt-4 flex justify-end">
            <Button variant="outline" on:click="{() => (show = false)}">Close</Button>
        </div>
    </Dialog.Content>
</Dialog.Root>
